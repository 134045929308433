import { createSlice } from "@reduxjs/toolkit";

const IntegrationsSlice = createSlice({
    name: "Integrations",
    initialState: {
        addIntegrationModalOpen: false,
    },
    reducers: {
        setAddIntegrationModalOpen: (state, action) => {
            state.addIntegrationModalOpen = action.payload;
        },
    },
    extraReducers: (builder) => {
        //
    }
});

export const { setAddIntegrationModalOpen } = IntegrationsSlice.actions;

export default IntegrationsSlice.reducer;

