import React, { useState, useEffect } from 'react'
import { DialogContent, DialogTitle, DialogActions, Button, CircularProgress, FormControlLabel, Radio, Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setAddIntegrationModalOpen } from '../../redux/slices/integrationsSlice';
import { useGetIntegrationsQuery, useGetIntegrationsForCustomerQuery, usePostSaveIntegrationMutation } from '../../services/integrations/integrationsApi';
import { INTEGRATIONS_DICTIONARY } from './Helpers';
import { Add } from '@mui/icons-material';

const AddIntegrationModal = () => {
    const dispatch = useDispatch()
    const { customerId } = useParams();

    const {
        data: integrations,
        error: integrationsError,
        isLoading: integrationsLoading
    } = useGetIntegrationsQuery(undefined, { forceRefetch: true })

    const {
        data: integrationsForCustomer,
        error: integrationsForCustomerError,
        isLoading: integrationsForCustomerLoading
    } = useGetIntegrationsForCustomerQuery({ customerId }, { forceRefetch: true })


    const [saveIntegration, { isLoading: saveIntegrationLoading, error: saveIntegrationError }] = usePostSaveIntegrationMutation()

    const [selectedIntegration, setSelectedIntegration] = useState(null)

    useEffect(() => {
        if (integrationsForCustomer?.integrations?.length > 0) {
            setSelectedIntegration(integrationsForCustomer.integrations[0])
        }
        return () => {
            setSelectedIntegration(null)
        }
    }, [integrationsForCustomer])

    const handleIntegrationSelect = (integration) => {
        setSelectedIntegration(integration)
    }

    const handleSave = () => {
        if (selectedIntegration) {
            saveIntegration({
                integrationId: selectedIntegration.integrationId,
                fieldInfo: [],
                customerId
            })
        } else {
            //todo: support selecting no integration
        }
        dispatch(setAddIntegrationModalOpen(false))
    }

    const handleCancel = () => {
        setSelectedIntegration(null)
        dispatch(setAddIntegrationModalOpen(false))
    }

    const error = integrationsError || integrationsForCustomerError || saveIntegrationError;
    const loading = integrationsLoading || integrationsForCustomerLoading || saveIntegrationLoading;

    return (
        <>
            <DialogTitle>Select Integration</DialogTitle>

            <DialogContent>

                {loading && (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}

                {error && (
                    <Box display="flex" justifyContent="center">
                        <div>Error: {error}</div>
                    </Box>
                )}

                {!loading && integrations && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        {integrations.map((integration, idx) => (
                            <FormControlLabel
                                key={idx}
                                control={

                                    <Radio
                                        checked={selectedIntegration?.integrationId === integration.integrationId}
                                        onClick={() => handleIntegrationSelect(integration)}
                                        color="secondary"
                                    />
                                }
                                label={
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        width="100%"
                                    >
                                        <img
                                            src={INTEGRATIONS_DICTIONARY[integration.integrationId].logoUrl}
                                            alt={INTEGRATIONS_DICTIONARY[integration.integrationId].alt}
                                            width='100'
                                            height='100'
                                            style={{ marginLeft: '50px' }}
                                        />
                                    </Box>
                                }
                                sx={{
                                    margin: '10px auto',
                                    width: '500px',
                                    height: '80px',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: '5px',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                    },
                                    '& .MuiFormControlLabel-label': {
                                        width: '100%'
                                    }
                                }}
                            />
                        ))}
                    </Box>
                )}
            </DialogContent>

            <DialogActions>

                <Button
                    onClick={handleCancel}
                    color="secondary"
                >
                    Cancel
                </Button>

                <Button
                    onClick={handleSave}
                    variant={'contained'}
                    color="secondary"
                >
                    Save Changes
                </Button>

            </DialogActions>
        </>
    )
}

export default AddIntegrationModal;